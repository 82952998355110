// https://app.logo.com/dashboard/your-logo-files
const Logo = () => {
  return (
    <svg
      viewBox="0 0 59.00725799999999 40.755569164109225"
      height="40.755569164109225"
      width="59.00725799999999"
      data-background-color="#1e293b"
      preserveAspectRatio="xMidYMid meet"
      id="tight-bounds"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <svg
          viewBox="0 0 59.00725799999999 40.755569164109225"
          height="40.755569164109225"
          width="59.00725799999999"
        >
          <g>
            <svg
              viewBox="0 0 59.00725799999999 40.755569164109225"
              height="40.755569164109225"
              width="59.00725799999999"
            >
              <g>
                <svg
                  viewBox="0 0 59.00725799999999 40.755569164109225"
                  height="40.755569164109225"
                  width="59.00725799999999"
                >
                  <g id="textblocktransform">
                    <svg
                      viewBox="0 0 59.00725799999999 40.755569164109225"
                      height="40.755569164109225"
                      width="59.00725799999999"
                      id="textblock"
                    >
                      <g>
                        <svg
                          viewBox="0 0 59.00725799999999 40.755569164109225"
                          height="40.755569164109225"
                          width="59.00725799999999"
                        >
                          <g>
                            <svg
                              viewBox="0 0 59.00725799999999 40.755569164109225"
                              height="40.755569164109225"
                              width="59.00725799999999"
                            >
                              <g>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0"
                                  y="0"
                                  viewBox="4.800000190734863 18.900001525878906 90.19998931884766 62.29999542236328"
                                  enableBackground="new 0 0 100 100"
                                  // xml:space="preserve"
                                  height="40.755569164109225"
                                  width="59.00725799999999"
                                  className="icon-icon-0"
                                  data-fill-palette-color="accent"
                                  id="icon-0"
                                >
                                  <path
                                    d="M19.6 35.9c0 6.4 5.2 11.6 11.6 11.6 6.4 0 11.6-5.2 11.6-11.6 0-6.4-5.2-11.6-11.6-11.6C24.8 24.3 19.6 29.5 19.6 35.9zM36.6 35.9c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4C34.2 30.5 36.6 32.9 36.6 35.9zM58.7 28.7c0 5.4 4.4 9.8 9.8 9.8 5.4 0 9.8-4.4 9.8-9.8 0-5.4-4.4-9.8-9.8-9.8C63.1 18.9 58.7 23.3 58.7 28.7zM72.1 28.7c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6S72.1 26.7 72.1 28.7zM92.1 58.2h-7.3c-2.3-15.7-11.1-18.1-16.3-18.1s-13.9 2.4-16.3 18.1h-5.9c-4.2-7.1-10-9-15.1-9s-11 1.9-15.1 9H7.9c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1h5.4c-1.2 3.6-2 8-2.5 13.3-0.1 1.7 1.1 3.2 2.8 3.4 1.7 0.2 3.2-1.1 3.4-2.8 1.3-15.2 6-22.9 14.1-22.9 8.1 0 12.9 7.7 14.1 22.9 0.1 1.6 1.5 2.9 3.1 2.9 0.1 0 0.2 0 0.3 0 1.7-0.1 3-1.6 2.8-3.4-0.4-5.4-1.3-9.7-2.5-13.3h43c1.7 0 3.1-1.4 3.1-3.1S93.8 58.2 92.1 58.2zM58.5 58.2c1.4-7.9 4.7-11.9 10-11.9 5.3 0 8.6 4 10 11.9H58.5z"
                                    data-fill-palette-color="accent"
                                    className="bu-text-title"
                                  />
                                </svg>
                              </g>
                            </svg>
                          </g>
                        </svg>
                      </g>
                    </svg>
                  </g>
                </svg>
              </g>
              <g />
            </svg>
          </g>
        </svg>
      </g>
      <defs />
    </svg>
  );
};

export default Logo;
