const AdminHome = () => {
  return (
    <>
      <div className=" w-full flex items-center justify-center bu-text-primary text-6xl font-bold bu-text-disable mt-80">
        Not Implemented Yet
      </div>
    </>
  );
};

export default AdminHome;
